import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./ProgramUnggulanTni.css";

const ProgramUnggulanTni = () => {
  return (
    <React.Fragment>
      <div className="superparent-program-unggulan-tni">
        <h1 className="main-text-bimbel-tni-polri">PILIHAN PAKET UNGGULAN<br /> TNI - POLRI & SEKOLAH KEDINASAN</h1>
        <div className="container-program-unggulan-tni">
          <div className="card-program-unggulan">
            <div className="title-program">
              <h3>PAKET AKURASI</h3>
              <h4 className="text-subtitle-prog-unggulan">Sukses Lolos TNI - Polri & Sekolah Kedinasan</h4>
              <a href="https://api.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Nia%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <button className="btn-program-unggulan-tni">
                  PILIH PAKET
                </button>
              </a>
            </div>
            <hr className="garis-pembatas" />
            <h4 className="title-fitur">Fitur Paket</h4>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Sistem Belajar Privat dan Group
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Fleksibilitas Waktu Belajar & Latihan
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Pilihan Program Sesuai Kebutuhan
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Modul Belajar
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Worksheet Prediktif Edumatrix Indonesia
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Workshop English Mastery
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Motivation Training
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Coaching Session
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Konsultasi Akademik Personal
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Tips & Trik Lulus Tes
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Pembinaan mental
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Training KESAMAPTAAN
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Medical Check up yang Akurat
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Bimbingan Belajar Psikologi
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Evaluasi Hasil Belajar setiap hari
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Free Konsultasi
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Progress Report Setiap Minggu
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Tersedia Workshop Aplikatif
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Dibimbing Mentor Profesional
            </p>
          </div>
          <div className="card-program-unggulan">
            <div className="title-program">
              <h3>PAKET KOMANDO TRAINING CAMP</h3>
              <h4 className="text-subtitle-prog-unggulan">Sukses Lolos TNI - Polri & Sekolah Kedinasan</h4>
              <a href="https://api.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Asyah%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <button className="btn-program-unggulan-tni">
                  PILIH PAKET
                </button>
              </a>
            </div>
            <hr className="garis-pembatas" />
            <h4 className="title-fitur">Fitur Paket</h4>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              CAMP Selama 4 Minggu
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Belajar 3 sesi Setiap hari
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Sistem Belajar Privat & Group
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Latihan Binsik Intensif
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Modul Super Prediktif
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Worksheet Prediktif Taruna Edumatrix
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Grup Belajar Exclusive
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Free Assesment tes Akademik, SKD & Psikologi
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Tryout Akademik SKD & Psikologi 2 - 3 kali
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Intensive Quiz
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Pendampingan Medical Check Up
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Pembinaan mental
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Personal Coaching
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Progress Report Setiap Minggu
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Full Akomodasi selama CAMP
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Motivation Training
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Training KESAMAPTAAN
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Free Konsultasi dan Pendampingan
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Seragam Latihan
            </p>
          </div>
          <div className="card-program-unggulan">
            <div className="title-program">
              <h3>PAKET PRESISI</h3>
              <h4>Sukses Lolos TNI - Polri & Sekolah Kedinasan</h4>
              <a href="https://api.whatsapp.com/send?phone=6285600422188&text=Halo%20Kak%20Dila%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <button className="btn-program-unggulan-tni">
                  PILIH PAKET
                </button>
              </a>
            </div>
            <hr className="garis-pembatas" />
            <h4 className="title-fitur">Fitur Paket</h4>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Sistem belajar Privat dan Group
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Jadwal belajar padat terukur
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Durasi waktu belajar 2-3 bulan
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Layanan full akomodasi dan non akomodasi
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Modul belajar
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Motivation Training
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Training Kesamaptaan Jasmani
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Bimbingan belajar Psikologi
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Bimbingan belajar Akademik
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Bimbingan belajar SKD/TKD
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Pembinaan mental
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Coaching session
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Tryout setiap minggu
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Pendampingan Medical check up
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Analisa dan evaluasi belajar setiap hari
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Progress report setiap minggu
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Free konsultasi
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} />{" "}
              Tersedia workshop aplikatif
            </p>
            <p className="isi-fitur-program">
              <FontAwesomeIcon className="icon-program-tni" icon={faCheck} /> Di
              bimbing mentor profesional
            </p>
          </div>
        </div>
      </div>

      {/* SLIDER */}
    </React.Fragment>
  );
};

export default ProgramUnggulanTni;
