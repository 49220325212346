import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Alumni from "../../Components/Alumni/Alumni";
import Asalsekolah from "../../Components/AsalSekolah/Asalsekolah";
import BestprogramSnbt from "../../Components/BestProgram/BestProgramSnbt/BestProgramSnbt";
import Bottombar from "../../Components/BottomBar/Bottombar";
import Faqtni from "../../Components/Faq/FaqTni/FaqTni";
import Floatingcta from "../../Components/FloatingCta/Floatingcta";
import Footer from "../../Components/Footer/Footer";
import Keunggulan from "../../Components/Keunggulan/Keunggulan";
import Liputan from "../../Components/Liputan/Liputan";
import Masterteacher from "../../Components/MasterTeacher/Masterteacher";
import NavWithPromo from "../../Components/Navbar/NavWithpromo";
import PaketBelajarIup from "../../Components/PaketBelajar/PaketBelajarIup/PaketBelajarIup";
import SliderIup from "../../Components/SlideTop/SliderIup/SliderIup";
import ListKotaIup from "../../Components/Tab/TabIup/ListKotaIup";
import Testimoni from "../../Components/Testimoni/Testimoni";
import Video from "../../Components/Video/Video";
import Voucer from "../../Components/Voucer/Voucer";
import SuccessSnbt from "../BimbelSnbt/SuccessSnbt/SuccessSnbt";
import GaleriKegiatan from "../BimbelSupercamp/GaleriKegiatan/GaleriKegiatan";
import PromoIup from "./PromoIup/PromoIup";

const BimbelIup = ({ setProgress }) => {
  useEffect(() => {
    setProgress(60);
    setTimeout(() => {
      setProgress(100);
    }, 50);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content="Bimbel Persiapan IUP UGM, IUP ITB, IUP Unair, SIMAK KKI UI, UTBK SNBT Terbaik
          #1 - Edumatrix Indonesia"
        />
        <title>
          Bimbel Persiapan IUP UGM, IUP ITB, IUP Unair, SIMAK KKI UI, UTBK SNBT
          Terbaik #1 - Edumatrix Indonesia
        </title>
        <link rel="canonical" href="https://app.edumatrix-indonesia.com/bimbel-iup-kki" />
      </Helmet>
      <NavWithPromo />
      <div className="container-all">
        <SliderIup />
        <BestprogramSnbt />
        <SuccessSnbt />
        <PaketBelajarIup />
        <GaleriKegiatan />
        <Video />
        <Voucer />
        <Testimoni />
        <Keunggulan />
        <Masterteacher />
        <Alumni />
        <Liputan />
        <ListKotaIup />
        <Faqtni />
        <Asalsekolah />
        <PromoIup />
      </div>
      <Footer />
      <Bottombar />
      <Floatingcta />
    </React.Fragment>
  );
};

export default BimbelIup;
