import { faClose, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./Floatingcta.css";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import icon_notif from "../../assets/whatsapp_notification.webp";

const Floatingcta = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false); // Menambahkan status untuk animasi penutupan modal

  const openModal = () => {
    setIsModalOpen(true);
    setIsClosing(false); // Reset animasi penutupan saat membuka modal
  };

  const closeModal = () => {
    setIsClosing(true); // Menandakan modal sedang ditutup
    setTimeout(() => {
      setIsModalOpen(false); // Setelah animasi selesai, baru sembunyikan modal
    }, 500); // Durasi yang sama dengan animasi fade-down
  };

  return (
    <div className="whatsapp-container">
      {/* Modal untuk memilih customer service */}
      {isModalOpen && (
        <div className={`modal ${isClosing ? "fade-down" : ""}`}>
          <div className="modal-content">
            <div className="parent-title-modal">
              <h3 className="text-pilih-cs">Pilih Customer Service</h3>
              <FontAwesomeIcon
                className="close"
                onClick={closeModal}
                icon={faClose}
              />
            </div>
            <ul className="service-list">
              {/* SARI */}
              <a href="https://api.whatsapp.com/send?phone=6285712217876&text=Halo%20Kak%20Sari%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <li className="service-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
                    alt=""
                    className="img-float-wa"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service-link"
                  >
                    Kak Sari - 085712217876
                    <br />
                    <span className="text-cs-edm">
                      (CS Edumatrix Indonesia)
                    </span>
                  </a>
                </li>
              </a>

              {/* PUTRI */}
              <a href="https://api.whatsapp.com/send?phone=6285724543040&text=Halo%20Kak%20Putri%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <li className="service-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
                    alt=""
                    className="img-float-wa"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service-link"
                  >
                    Kak Putri - 085724543040
                    <br />
                    <span className="text-cs-edm">
                      (CS Edumatrix Indonesia)
                    </span>
                  </a>
                </li>
              </a>

              {/* ASYAH */}
              <a href="https://api.whatsapp.com/send?phone=6281215523902&text=Halo%20Kak%20Asyah%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <li className="service-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
                    alt=""
                    className="img-float-wa"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service-link"
                  >
                    Kak Asyah - 081215523902
                    <br />
                    <span className="text-cs-edm">
                      (CS Edumatrix Indonesia)
                    </span>
                  </a>
                </li>
              </a>

              {/* IVA */}
              <a href="https://api.whatsapp.com/send?phone=6282174144728&text=Halo%20Kak%20Iva%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <li className="service-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
                    alt=""
                    className="img-float-wa"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service-link"
                  >
                    Kak Iva - 082174144728
                    <br />
                    <span className="text-cs-edm">
                      (CS Edumatrix Indonesia)
                    </span>
                  </a>
                </li>
              </a>

              {/* NIA */}
              <a href="https://web.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Nia%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <li className="service-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
                    alt=""
                    className="img-float-wa"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service-link"
                  >
                    Kak Nia - 081216365729
                    <br />
                    <span className="text-cs-edm">
                      (CS Edumatrix Indonesia)
                    </span>
                  </a>
                </li>
              </a>

              {/* DILA */}
              <a href="https://api.whatsapp.com/send?phone=6285600422188&text=Halo%20Kak%20Dila%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20tanya%20program%20belajar%20yang%20ada%20di%20Edumatrix.%20Apa%20saja%20jenis%20program%20belajar%20dan%20pilihan%20paket%20sesinya?">
                <li className="service-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png?20200503174721"
                    alt=""
                    className="img-float-wa"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="service-link"
                  >
                    Kak Dila - 085600422188
                    <br />
                    <span className="text-cs-edm">
                      (CS Edumatrix Indonesia)
                    </span>
                  </a>
                </li>
              </a>
            </ul>
          </div>
        </div>
      )}

      {/* Floating WhatsApp Button */}
      <div className="main-parent-float-wa">
        <label className="label-cta" htmlFor="">
          Chat With Us
        </label>
        <div className="whatsapp-float">
          <button className="whatsapp-btn" onClick={openModal}>
            <FontAwesomeIcon className="icon-wa" icon={faWhatsapp} />
            <span className="whatsapp-badge">1</span>{" "}
            {/* Badge dengan angka 1 */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Floatingcta;
