import React, { useState } from "react";
import imgPopup from "../../assets/promo popup live class.webp";
import "./PopUp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const PopUp = () => {
  // State untuk mengontrol apakah pop-up ditampilkan atau tidak
  const [isOpen, setIsOpen] = useState(true);

  // Fungsi untuk menutup pop-up
  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="container-popup">
          <a href="https://api.whatsapp.com/send?phone=6281216365729&text=Halo%20Kak%20Nia%20https://app.edumatrix-indonesia.com,%20Saya%20ingin%20daftar%20Live%20Class%20Bimbel%20TNI-POLRI" className="parent-img-popup">
            <img src={imgPopup} alt="Pop-up Promo" className="img-popup" />
          </a>
          <button className="close-btn" onClick={closePopup}>
            {/* <span class="material-symbols-outlined">close</span> */}
            <FontAwesomeIcon className="icon-close" icon={faClose} />
          </button>
        </div>
      )}
    </>
  );
};

export default PopUp;
